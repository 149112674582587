import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import InsertEmoticon from '@mui/icons-material/InsertEmoticon';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Picker from 'emoji-picker-react';
import { useParams } from 'react-router-dom';
import ChatMessage from './ChatMessage';
import StartConversationByTemplate from '../Templates/startConversationByTemplate';
import './ChatContainer.css';
import axios from 'axios';
import ChatTimelineMessage from './ChatTimelineMessage';
import LoadingOverlay from '../General/LoadingOverlay';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CloseIcon from '@mui/icons-material/Close';
const ChatContainer = ({ currentUser, setCurrentChatRecipient, messageWebSocket, timelineWebSocket, selectedContact, categories, users, onCategoriesUpdate }) => {
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false); // Add state for loading

    const [openEmojiBox, setOpenEmojiBox] = useState(false);
    const { contactphonenumber } = useParams();
    const [chatMessages, setChatMessages] = useState([]);
    const [filteredMessages, setFilteredMessages] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showStarredOnly, setShowStarredOnly] = useState(false);
    const chatBox = useRef(null);
    const [IsConversationLiveByPhoneNumber, setIsConversationLiveByPhoneNumber] = useState(false);
    const [isRecipientReplyLast24Hours, setisRecipientReplyLast24Hours] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    // const [users, setUsers] = useState([]);
    const [currentOwner, setCurrentOwner] = useState(null);
    const [showChangeOwner, setShowChangeOwner] = useState(false);
    // const [conversationStatus, setConversationStatus] = useState("Open"); // New state for conversation status
    const [conversationStatus, setConversationStatus] = useState(""); // Initialize as empty string
    // const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [showNewCategoryInput, setShowNewCategoryInput] = useState(false); // Corrected name
    const [newCategoryName, setNewCategoryName] = useState(""); // Corrected name
    const [newCategoryDetails, setNewCategoryDetails] = useState(""); // To capture additional details if needed
    const [conversation, setConversation] = useState();
    const [timelines, setTimelines] = useState([]); // New state for timelines
    const [filteredCombinedMessages, setFilteredCombinedMessages] = useState([]);

    const [expirationTime, setExpirationTime] = useState(null); // New state for expiration time
    const [timeRemaining, setTimeRemaining] = useState(null);   // New state for countdown
    const chatMessagesRef = useRef(chatMessages);
    const timelinesRef = useRef(timelines);
    const timeRemainingRef = useRef(null); // Reference to the DOM element for countdown
    const intervalRef = useRef(null); // Reference to the interval

    const [mediaPreview, setMediaPreview] = useState(null);
    const [mediaFile, setMediaFile] = useState(null);
    const fileInputRef = useRef(null);


    const [isMediaExpanded, setIsMediaExpanded] = useState(false);
    const [expandedMedia, setExpandedMedia] = useState(null); // To track which media is expanded

    const [mediaFiles, setMediaFiles] = useState([]); // Multiple files


    // Handle media upload
    const handleMediaChange = (event) => {
        const files = event.target.files;
        if (files?.length > 0) {
            const previews = [];
            Array.from(files).forEach((file) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    previews.push({
                        url: reader.result,
                        type: file.type,
                        name: file.name,
                    });
                    if (previews?.length === files?.length) {
                        setMediaPreview(previews);
                        setMediaFiles(Array.from(files));
                    }
                };
                reader.readAsDataURL(file);
            });
        }
    };

    // Handle media removal
    const handleRemoveMedia = (index) => {
        const updatedPreview = [...mediaPreview];
        const updatedFiles = [...mediaFiles];
        updatedPreview.splice(index, 1);
        updatedFiles.splice(index, 1);
        setMediaPreview(updatedPreview);
        setMediaFiles(updatedFiles);
    };

    // Handle full-screen media preview
    const handleMediaClick = (media) => {
        setExpandedMedia(media);
        setIsMediaExpanded(true);
    };

    const closeMediaModal = () => {
        setIsMediaExpanded(false);
        setExpandedMedia(null);
    };
    console.log(mediaFile);
    console.log(mediaPreview);
    // Update ref values after state changes
    useEffect(() => {
        chatMessagesRef.current = chatMessages;
    }, [chatMessages]);

    useEffect(() => {
        timelinesRef.current = timelines;
    }, [timelines]);

    const scrollToBottom = () => {
        if (chatBox.current) {
            chatBox.current.scrollTop = chatBox.current.scrollHeight;
        }
    };

    // Use Effect to Scroll on Message Load
    useEffect(() => {
        scrollToBottom();
    }, [chatMessages, timelines]);

    const handleCategoryChange = async (event) => {
        const value = event.target.value;
        setSelectedCategory(value);

        if (value === "add_new") {
            setShowNewCategoryInput(true); // Show input to add new category
        } else {
            // Update the conversation category in the backend
            try {
                await axios.post("https://gambot.azurewebsites.net/api/Webhooks/updateConversationCategory", {
                    organization: currentUser.organization,
                    phoneNumber: contactphonenumber,
                    category: value,
                    modifiedById: currentUser?.userId
                });
            } catch (error) {
                console.error("Error updating conversation category:", error);
            }
        }
    };



    const handleAddNewCategory = async () => {
        if (!newCategoryName.trim()) return;

        try {
            await axios.post("https://gambot.azurewebsites.net/api/Webhooks/addConversationCategory", {
                organization: currentUser.organization,
                label: newCategoryName,
                details: newCategoryDetails,
                createdBy: currentUser?.userId
            });

            // Fetch updated categories after adding the new one
            const response = await axios.post("https://gambot.azurewebsites.net/api/Webhooks/getConversationCategories", {
                organization: currentUser.organization,
            });
            onCategoriesUpdate(response.data || []);
            // setCategories(response.data || []);
            setSelectedCategory(newCategoryName);
            setShowNewCategoryInput(false); // Close input form
            setNewCategoryName(""); // Clear input field
            setNewCategoryDetails("");
        } catch (error) {
            console.error("Error adding category:", error);
        }
    };
    const handleStatusChange = async (event) => {
        const newStatus = event.target.value;
        setConversationStatus(newStatus);
        try {
            await axios.post("https://gambot.azurewebsites.net/api/Webhooks/updateConversationStatus", {
                organization: currentUser.organization,
                phoneNumber: contactphonenumber,
                status: newStatus,
                modifiedById: currentUser?.userId
            });
        } catch (error) {
            console.error("Error updating conversation status:", error);
        }
    };
    const fetchConversationStatus = useCallback(async () => {
        try {
            const response = await axios.post("https://gambot.azurewebsites.net/api/Webhooks/GetConversationStatus", {
                organization: currentUser.organization,
                phoneNumber: contactphonenumber,
            });
            if (response.data) {
                setConversationStatus(response.data.Data);
            }
        } catch (error) {
            console.error("Error fetching conversation status:", error);
        }
    }, [currentUser.organization, contactphonenumber]);

    useEffect(() => {
        fetchConversationStatus();
    }, [fetchConversationStatus]);

    // Fetch conversation expiration time
    useEffect(() => {
        if (!expirationTime) return;

        const updateCountdown = () => {
            const now = new Date();
            const diff = expirationTime - now;

            if (diff <= 0) {
                clearInterval(intervalRef.current);
                if (timeRemainingRef.current) {
                    timeRemainingRef.current.textContent = "00:00:00";
                }
            } else {
                const hours = Math.floor(diff / (1000 * 60 * 60));
                const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((diff % (1000 * 60)) / 1000);

                if (timeRemainingRef.current) {
                    timeRemainingRef.current.textContent = `${hours}:${minutes}:${seconds}`;
                }
            }
        };

        if (intervalRef.current) clearInterval(intervalRef.current);
        intervalRef.current = setInterval(updateCountdown, 1000);

        updateCountdown(); // Initial update

        return () => clearInterval(intervalRef.current); // Cleanup
    }, [expirationTime, timeRemainingRef]);
    useEffect(() => {
        console.log("timeRemainingRef current value:", timeRemainingRef.current);
    }, [timeRemainingRef]);
    console.log(timeRemainingRef);
    // Fetch expiration time (unchanged)
    const fetchExpirationTime = useCallback(async () => {
        try {
            const response = await axios.post("https://gambot.azurewebsites.net/api/Webhooks/GetConversationExpirationTime", {
                organization: currentUser.organization,
                phoneNumber: contactphonenumber
            });

            if (response.data) {
                const expirationTimestamp = response.data;
                setExpirationTime(new Date(expirationTimestamp));
            } else {
                setExpirationTime(null); // No expiration time
            }
        } catch (error) {
            console.error("Error fetching conversation expiration time:", error);
        }
    }, [currentUser.organization, contactphonenumber]);
    useEffect(() => {
        fetchExpirationTime();
    }, [fetchExpirationTime]);

    const checkConversationStatus = async (organization, phoneNumber) => {
        try {
            const liveResponse = await axios.post("https://gambot.azurewebsites.net/api/Webhooks/IsConversationLiveByPhoneNumber", { organization, phoneNumber });
            setIsConversationLiveByPhoneNumber(liveResponse.data);

            const replyResponse = await axios.post("https://gambot.azurewebsites.net/api/Webhooks/isRecipientReplyLast24Hours", { organization, phoneNumber });
            setisRecipientReplyLast24Hours(replyResponse.data);

            if (isRecipientReplyLast24Hours) {

            }
        } catch (error) {
            console.error("Error checking conversation status", error);
        }
    };

    const combinedMessagesAndTimelines = useMemo(() => {
        const combined = [...chatMessages, ...timelines];
        return combined.sort((a, b) => new Date(a.createdOn) - new Date(b.createdOn));
    }, [chatMessages, timelines]);



    const fetchChatMessages = useCallback(async () => {
        setIsLoading(true); // Start loading

        try {
            const response = await axios.post('https://gambot.azurewebsites.net/api/Webhooks/GetMessagesByPhoneNumber', {
                organizationiD: currentUser.organization,
                phoneNumber: contactphonenumber,
            });
            setChatMessages(prev => {
                const newMessages = response.data || [];
                if (JSON.stringify(prev) !== JSON.stringify(newMessages)) {
                    return newMessages;
                }
                return prev;
            });
        } catch (error) {
            console.error('Error fetching chat messages:', error);
        }
        finally {
            setIsLoading(false); // Stop loading
        }
    }, [contactphonenumber, currentUser.organization]);

    const fetchTimelines = useCallback(async () => {
        setIsLoading(true); // Start loading

        try {
            const response = await axios.post('https://gambot.azurewebsites.net/api/Webhooks/GetTimelineByPhoneNumber', {
                organization: currentUser.organization,
                phoneNumber: contactphonenumber,
            });
            setTimelines(prev => {
                const newTimelines = response.data || [];
                if (JSON.stringify(prev) !== JSON.stringify(newTimelines)) {
                    return newTimelines;
                }
                return prev;
            });
        } catch (error) {
            console.error('Error fetching timelines:', error);
        }
        finally {
            setIsLoading(false); // Stop loading

        }
    }, [contactphonenumber, currentUser.organization]);

    // Fetch data on component mount
    useEffect(() => {
        if (contactphonenumber) {
            checkConversationStatus(currentUser.organization, contactphonenumber);
        }
    }, [contactphonenumber]);
    useEffect(() => {
        if (contactphonenumber) {
            fetchChatMessages();
            fetchTimelines();
        }
    }, [contactphonenumber, fetchChatMessages, fetchTimelines]);

    useEffect(() => {
        const handleMessageEvent = (event) => {
            try {
                const data = JSON.parse(event.data);
                if (data.type === 'messages') {
                    const newMessages = JSON.parse(data.data);

                    setChatMessages((prev) => {
                        const existingIds = new Set(prev.map((msg) => msg.messageId));
                        const filteredMessages = newMessages.filter((msg) => !existingIds.has(msg.messageId));
                        // Prepend new messages to keep them on top
                        return [...filteredMessages, ...prev];
                    });
                }
            } catch (error) {
                console.error('Error processing WebSocket message:', error);
            }
        };

        if (messageWebSocket) {
            messageWebSocket.addEventListener('message', handleMessageEvent);
        }

        return () => {
            if (messageWebSocket) {
                messageWebSocket.removeEventListener('message', handleMessageEvent);
            }
        };
    }, [messageWebSocket]);


    useEffect(() => {
        const handleTimelineEvent = (event) => {
            try {
                const data = JSON.parse(event.data);
                if (data.type === 'timelines') {
                    const newTimelines = JSON.parse(data.data);

                    setTimelines((prev) => {
                        const existingIds = new Set(prev.map((tl) => tl.TimelineId));
                        const filteredTimelines = newTimelines.filter((tl) => !existingIds.has(tl.TimelineId));
                        // Prepend new timelines to keep them on top
                        return [...filteredTimelines, ...prev];
                    });
                }
            } catch (error) {
                console.error('Error processing WebSocket timeline data:', error);
            }
        };

        if (timelineWebSocket) {
            timelineWebSocket.addEventListener('message', handleTimelineEvent);
        }

        return () => {
            if (timelineWebSocket) {
                timelineWebSocket.removeEventListener('message', handleTimelineEvent);
            }
        };
    }, [timelineWebSocket]);





    const send = async (e) => {
        e.preventDefault();

        if (!contactphonenumber) return;

        try {
            // Set loading state to true
            setIsLoading(true);

            if (mediaFiles.length > 0) {
                // Handle media message for each file
                for (const file of mediaFiles) {
                    const formData = new FormData();
                    formData.append('phoneNumber', contactphonenumber);
                    formData.append('Org', currentUser.organization);
                    formData.append('userId', currentUser.userId);
                    formData.append('source', 'chat');
                    formData.append('caption', message.trim() || '');
                    formData.append('File', file); // Attach the file
                    formData.append('file_type', file.type);
                    formData.append('file_length', file.size);
                    for (let pair of formData.entries()) {
                        console.log(`${pair[0]}: ${pair[1]}`);
                    }
                    await axios.post("https://gambot.azurewebsites.net/api/Webhooks/CreateWabaMediaMessages", formData, {
                        headers: { 'Content-Type': 'multipart/form-data' },
                    });
                }

                // Clear state after sending
                setMessage('');
                setMediaFiles([]);
                setMediaPreview([]);
            } else if (message.trim() !== '') {
                // Handle text message
                const messageText = message.replace(/(?:\r\n|\r|\n)/g, '\\n');
                const payload = {
                    text: messageText,
                    from: currentUser.wabaNumber,
                    to: contactphonenumber,
                    senderEmail: currentUser.email,
                    receiverEmail: contactphonenumber,
                    timeStamp: '',
                    sentByName: currentUser.fullname,
                    sentById: currentUser.userId,
                    organization: currentUser.organization,
                };
                console.log(JSON.stringify(payload));
                // API call for text message
                await axios.post("https://gambot.azurewebsites.net/api/Webhooks/CreateOutboundMessage", payload);

                // Clear state after sending
                setMessage('');
            }

            // Refresh chat messages
            setCurrentChatRecipient(contactphonenumber);
            fetchChatMessages(contactphonenumber);
        } catch (error) {
            console.error('Error sending message:', error.response?.data || error.message);
        } finally {
            // Set loading state to false
            setIsLoading(false);
        }
    };


    const handleKeyPress = (event) => {
        if ((event.key === 'Enter' && event.shiftKey) || (event.key === 'Enter' && event.altKey)) {
            // Prevent default action and insert a newline
            event.preventDefault();
            setMessage((prevMessage) => prevMessage + '\n');
        } else if (event.key === 'Enter') {
            // Prevent default and trigger the send action
            event.preventDefault();
            send(event);
        }
    };

    const toggleStar = async (messageId, newStarredState) => {
        try {
            await axios.post("https://gambot.azurewebsites.net/api/Webhooks/ToggleStarredMessage", {
                messageId,
                phoneNumber: contactphonenumber,
                organizationId: currentUser.organization,
            });

            // Update local state for immediate feedback
            setChatMessages((prevMessages) =>
                prevMessages.map((msg) =>
                    msg.messageId === messageId ? { ...msg, isStarred: newStarredState } : msg
                )
            );

            // Apply filters to update displayed messages
            applyFilters();
        } catch (error) {
            console.error("Error updating star status:", error);
        }
    };

    const applyFilters = useCallback(() => {
        let filtered = combinedMessagesAndTimelines;

        // Apply "starred only" filter
        if (showStarredOnly) {
            filtered = filtered.filter(item => item.isStarred);
        }

        // Apply search term filter
        if (searchTerm) {
            filtered = filtered.filter(item =>
                item.text?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.timelineMessage?.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        setFilteredCombinedMessages(filtered);
    }, [combinedMessagesAndTimelines, showStarredOnly, searchTerm]);
    useEffect(() => {
        applyFilters();
    }, [chatMessages, timelines, applyFilters]);
    
    const toggleShowStarred = () => {
        setShowStarredOnly(prev => !prev);
    };

    useEffect(() => {
        scrollToBottom();
    }, [filteredCombinedMessages]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };
    
    const filterMessages = (searchTerm, showStarred) => {
        let filtered = chatMessages;
        if (showStarred) {
            filtered = filtered.filter(msg => msg.isStarred);
        }
        if (searchTerm) {
            filtered = filtered.filter(msg =>
                msg.text.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }
        setFilteredMessages(filtered);
        setChatMessages(filtered); // Update chatMessages with the filtered list

    };


    const fetchCurrentOwner = useCallback(async () => {
        if (selectedContact && selectedContact.ownerId) {
            console.log(users);
            console.log(users[0]?.uID);
            console.log(selectedContact?.ownerId);
            const owner = users.find(user => user.uID === selectedContact.ownerId);
            setCurrentOwner(owner || null);
        }
    }, [users]);

    const handleChangeOwner = async (newOwnerId) => {
        try {
            await axios.post("https://gambot.azurewebsites.net/api/Webhooks/UpdateContactOwner", {
                contactPhoneNumber: contactphonenumber,
                ownerId: newOwnerId,
                organization: currentUser.organization
            });
            const newOwner = users?.find(user => user.uID === newOwnerId);
            setCurrentOwner(newOwner);
            setShowChangeOwner(false);
        } catch (error) {
            console.error("Error updating contact owner:", error);
        }
    };
    useEffect(() => {
        fetchCurrentOwner();
    }, [fetchCurrentOwner, selectedContact]);


    useEffect(() => {
        setExpirationTime(null);
        setTimeRemaining(null);
    }, [contactphonenumber, selectedContact]);

    console.log(combinedMessagesAndTimelines);
    console.log(filteredCombinedMessages);
    return (
        <div className='chat-container'>
            <LoadingOverlay loading={isLoading} /> {/* Add the overlay */}

            <div className='chat-container-header'>
                {/* Row 1: User, Owner, Assign To */}
                <div className='header-row'>
                    <p>{selectedContact?.name + " " + contactphonenumber || ""}</p>
                    <div className='chat-user-details'>
                        <p>Owner: {currentOwner ? currentOwner.UserName : "Unassigned"}</p>
                        <button className='change-owner-button' onClick={() => setShowChangeOwner(!showChangeOwner)}>
                            Assign To
                        </button>


                        {showChangeOwner && (
                            <select className='owner-select' onChange={(e) => handleChangeOwner(e.target.value)} value={currentOwner?.uID || ""}>
                                <option value="" disabled>Select new owner</option>
                                {users.map(user => (
                                    <option key={user.uID} value={user.uID}>{user.UserName}</option>
                                ))}
                            </select>
                        )}
                    </div>
                </div>

                {/* Row 2: Category and Status */}
                <div className='header-row'>

                    <div className="category-select">
                        <label>Select Category:</label>
                        <select value={selectedCategory} onChange={handleCategoryChange}>
                            <option value="">Select a category</option>
                            {categories.map((category) => (
                                <option key={category} value={category}>{category}</option>
                            ))}
                            <option value="add_new">Add New Category</option>
                        </select>
                    </div>
                    <div className='chat-expiration-time'>
                        {expirationTime ? (
                            <div className='countdown-timer'>
                                <span ref={timeRemainingRef} className="time-circle"></span>
                            </div>
                        ) : (
                            <div className='no-time-remaining'>
                                <span>No active expiration time.</span>
                            </div>
                        )}
                    </div>
                    <div className='chat-status-selector'>
                        <label htmlFor='status'>Status:</label>
                        <select
                            id='status'
                            value={conversationStatus}
                            onChange={handleStatusChange}
                        >
                            <option value="Open">Open</option>
                            <option value="In Process">In Process</option>
                            <option value="Closed">Closed</option>
                        </select>
                    </div>
                </div>

                {/* Row 3: Search Messages and Starred Messages */}
                <div className='header-row'>
                    <div className="chat-container-chat-search">
                        <input
                            type="text"
                            placeholder="Search messages"
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                    </div>
                    <button
                        className="chat-container-starred-messages-header"
                        onClick={toggleShowStarred}
                    >
                        {showStarredOnly ? <StarIcon style={{ color: 'gold' }} /> : <StarBorderIcon />}
                    </button>
                </div>
            </div>


            <div className='chat-display-container' ref={chatBox}>
                {(filteredCombinedMessages?.length > 0 ? filteredCombinedMessages : combinedMessagesAndTimelines).map((item, index) => {
                    if (item.messageId) { // Check if item contains 'messageId'
                        return (
                            <ChatMessage
                                key={index}
                                message={item.text}
                                time={item.timeStamp}
                                sender={contactphonenumber}
                                from={item.from}
                                userName={item.sentByName}
                                messageType={item.type}
                                createdOn={item.createdOn}
                                currentUser={currentUser}
                                isStarred={item.isStarred}
                                onStarToggle={toggleStar}
                                orgazanizationNumber={currentUser.wabaNumber}
                                item={item}
                            />
                        );
                    } else {
                        return (
                            <ChatTimelineMessage
                                key={index}
                                timelineMessage={item.timelineMessage}
                                createdOn={item.createdOn}
                                currentUser={currentUser}
                                item={item}
                            />
                        );
                    }
                })}
            </div>



            <div className='chat-input'>
                {IsConversationLiveByPhoneNumber && !isRecipientReplyLast24Hours ? (
                    <p>Wait for user reply</p>
                ) : (isRecipientReplyLast24Hours && IsConversationLiveByPhoneNumber)
                    || (isRecipientReplyLast24Hours && !IsConversationLiveByPhoneNumber) ? (
                    <>
                        {openEmojiBox && <Picker onEmojiClick={(event, emojiObject) => setMessage(message + emojiObject.emoji)} />}
                        <div className='chat-input-btn'>
                            <InsertEmoticon onClick={() => setOpenEmojiBox(!openEmojiBox)} />
                            <AttachFileIcon onClick={() => fileInputRef.current.click()} />
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                accept="image/*,video/*,application/pdf"
                                multiple // Allow multiple files
                                onChange={handleMediaChange}
                            />                            </div>
                        <form onSubmit={send}>
                            <textarea
                                placeholder='Type a message'
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                onKeyDown={handleKeyPress}
                                rows="3"
                            />
                        </form>

                        {/* Media Previews */}
                        {mediaPreview?.length > 0 && (
                            <div className="chat-container-media-preview">
                                {mediaPreview.map((file, index) => {
                                    const isImage = file.type.startsWith('image');
                                    const isVideo = file.type.startsWith('video');
                                    const isDocument = file.type.startsWith('application');

                                    return (
                                        <div key={index} className="chat-container-media-preview-item">
                                            {isImage && (
                                                <img
                                                    src={file.url}
                                                    alt={file.name}
                                                    className="chat-container-media-preview-img"
                                                    onClick={() => handleMediaClick(file)}
                                                />
                                            )}
                                            {isVideo && (
                                                <video
                                                    controls
                                                    className="chat-container-media-preview-video"
                                                    onClick={() => handleMediaClick(file)}
                                                >
                                                    <source src={file.url} type={file.type} />
                                                    Your browser does not support videos.
                                                </video>
                                            )}
                                            {isDocument && (
                                                <div className="chat-container-media-preview-document">
                                                    <a href={file.url} download={file.name}>
                                                        {file.name}
                                                    </a>
                                                </div>
                                            )}
                                            <CloseIcon
                                                fontSize='small'
                                                className="chat-container-close-icon"
                                                onClick={() => handleRemoveMedia(index)}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                        {/* Full-Screen Modal */}
                        {isMediaExpanded && expandedMedia && (
                            <div className="media-modal" onClick={closeMediaModal} style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.8)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {expandedMedia.type.startsWith('image') && (
                                    <img src={expandedMedia.url} alt={expandedMedia.name} style={{ maxHeight: '80%', maxWidth: '80%' }} />
                                )}
                                {expandedMedia.type.startsWith('video') && (
                                    <video controls style={{ maxHeight: '80%', maxWidth: '80%' }}>
                                        <source src={expandedMedia.url} type={expandedMedia.type} />
                                    </video>
                                )}
                            </div>
                        )}

                        <div className='chat-input-send-btn' onClick={send}>
                            <SendIcon />
                        </div>
                    </>
                ) : (
                    <button onClick={() => setShowPopup(true)}>Start New Conversation</button>
                )}
            </div>

            {showPopup && (
                <div className='popup'>
                    <div className='popup-inner'>
                        <StartConversationByTemplate
                            currentUser={currentUser}
                            contactPhoneNumber={contactphonenumber}
                            onClose={() => setShowPopup(false)}
                            onMessageSent={(newMessage) => {
                                setChatMessages((prevMessages) => {
                                    const updatedMessages = [...prevMessages, newMessage];
                                    // Ensure combined messages update
                                    const updatedCombined = [...updatedMessages, ...timelines];
                                    setFilteredCombinedMessages(
                                        updatedCombined.sort((a, b) => new Date(a.createdOn) - new Date(b.createdOn))
                                    );
                                    return updatedMessages;
                                });
                                // Scroll to the bottom to show the new message
                                scrollToBottom();
                            }}
                        />
                    </div>
                </div>
            )}

            {/* Popup Form for Adding New Category */}
            {showNewCategoryInput && (
                <div className="chat-new-category-modal">
                    <div className="chat-new-category-modal-content">
                        <h3>Add New Category</h3>
                        <label>
                            Category Name:
                            <input
                                type="text"
                                value={newCategoryName}
                                onChange={(e) => setNewCategoryName(e.target.value)}
                                placeholder="Enter category name"
                            />
                        </label>
                        <label>
                            Details:
                            <br></br>
                            <textarea className='chat-new-category-modal-details-text-area'
                                value={newCategoryDetails}
                                onChange={(e) => setNewCategoryDetails(e.target.value)}
                                placeholder="Enter category details"
                            />
                        </label>
                        <div className="chat-new-category-modal-actions">
                            <button className='chat-new-category-modal-actions-cancel' onClick={() => setShowNewCategoryInput(false)}>Cancel</button>
                            <button className='chat-new-category-modal-actions-submit' onClick={handleAddNewCategory}>Submit</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default React.memo(ChatContainer);
