import React, { useState, useEffect, useCallback } from 'react';
import TemplatesHandler from '../../Templates/TemplatesHandler';
import ConditionGroup from './ConditionGroup';
import './OutboundMessageTrigger.css';

const OutboundMessageTrigger = ({ organization, onChange, initialConfig = {}  , parent}) => {
  const [messageType, setMessageType] = useState(initialConfig.messageType || 'regular');
  const [templateId, setTemplateId] = useState(initialConfig.templateId || null);
  const [templateVariableQuery, setTemplateVariableQuery] = useState(initialConfig.templateVariableQuery || []);
  const [messageContent, setMessageContent] = useState(initialConfig.messageContent || '');
  const [conditionGroups, setConditionGroups] = useState(
    initialConfig.conditionGroups || [{ logicOperator: 'AND', conditions: [{ operator: 'equals', value: '' }] }]
  );
  const [groupRelations, setGroupRelations] = useState(initialConfig.groupRelations || []);
  const [selectedTemplates, setSelectedTemplates] = useState(initialConfig.selectedTemplates || []);
  const [selectedButtons, setSelectedButtons] = useState(initialConfig.selectedButtons || []);

  useEffect(() => {
    onChange({
      messageType,
      templateId,
      templateVariableQuery,
      messageContent,
      conditionGroups,
    });
  }, [messageType, templateId, templateVariableQuery, messageContent, conditionGroups, onChange]);

  const handleMessageTypeChange = (e) => {
    setMessageType(e.target.value);
  };
// Trigger onChange when the state changes
const triggerOnChange = useCallback(() => {
  const newConfig = { 
    messageType, 
    templateId, // Add this to the configuration
    templateVariableQuery, 
    conditionGroups, 
    groupRelations, 
    selectedTemplates, 
    selectedButtons 
  };
  if (JSON.stringify(newConfig) !== JSON.stringify(initialConfig)) {
    onChange(newConfig);
  }
}, [messageType, templateId, templateVariableQuery, conditionGroups, groupRelations, selectedTemplates, selectedButtons, onChange, initialConfig]);

useEffect(() => {
  triggerOnChange();
}, [triggerOnChange]);


const handleGroupChange = (index, updatedGroup) => {
  setConditionGroups((prevGroups) => {
    const newGroups = [...prevGroups];
    newGroups[index] = updatedGroup;
    return newGroups;
  });
};

const addGroup = () => {
  setConditionGroups((prevGroups) => [
    ...prevGroups,
    { logicOperator: 'AND', conditions: [{ operator: 'equals', value: '' }] },
  ]);
  setGroupRelations((prevRelations) => [...prevRelations, 'AND']);
};

const handleDeleteGroup = (index) => {
  setConditionGroups((prevGroups) => prevGroups.filter((_, i) => i !== index));
  setGroupRelations((prevRelations) => prevRelations.filter((_, i) => i !== index - 1));
};

const handleAddCondition = (groupIndex) => {
  setConditionGroups((prevGroups) => {
    const newGroups = [...prevGroups];
    newGroups[groupIndex].conditions.push({ operator: 'equals', value: '' });
    return newGroups;
  });
};

const handleGroupRelationChange = (index, value) => {
  setGroupRelations((prevRelations) => {
    const newRelations = [...prevRelations];
    newRelations[index] = value;
    return newRelations;
  });
};
const handleTemplatesChange = (selectedOptions, buttons) => {
  setSelectedTemplates(selectedOptions);
  setSelectedButtons(buttons);
};

  const handleTemplatesVariableChange = (template, variables, queries) => {
    setTemplateId(template?.Id);
    setTemplateVariableQuery(queries);
  };

  return (
    <div className="outbound-message-trigger">
      <label>
        Message Type:
        <select value={messageType} onChange={handleMessageTypeChange}>
          <option value="regular">Regular</option>
          <option value="template">Template</option>
        </select>
      </label>

      {/* Regular Message Filtering */}
      {messageType === 'regular' && (
        <div className="condition-groups">
          {conditionGroups.map((group, index) => (
            <div key={index} className="condition-group-wrapper">
              <ConditionGroup
                group={group}
                onGroupChange={(updatedGroup) => handleGroupChange(index, updatedGroup)}
                onDeleteGroup={() => handleDeleteGroup(index)}
                onAddCondition={() => handleAddCondition(index)}
                isRemovable={index > 0}
                parent={parent}
              />
            </div>
          ))}
          <div className="add-group-wrapper">
            <button type="button" onClick={addGroup} className="condition-action-add-group-wrapper">
              Add Group
            </button>
            {conditionGroups.length > 0 && (
              <select
                value={groupRelations[groupRelations.length - 1] || 'AND'}
                onChange={(e) => handleGroupRelationChange(groupRelations.length - 1, e.target.value)}
                className="group-relation-select"
              >
                <option value="AND">AND</option>
                <option value="OR">OR</option>
              </select>
            )}
          </div>
        </div>
      )}
      {messageType === 'template' && (
        <TemplatesHandler
          organization={organization}
          onTemplatesVariableChange={handleTemplatesVariableChange}
          templateId={templateId}
          templateVariableQuery={templateVariableQuery}
          recipientSource={'Contacts'}
          parent={'OutboundMessageTrigger'}
        />
      )}
    </div>
  );
};

export default OutboundMessageTrigger;
