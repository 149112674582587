import React, { useState, useEffect, useRef } from "react";
import { FaTrash } from "react-icons/fa";
import ActionInputField from "../WorkflowDesigner/ActionInputField";
import "./ConditionRow.css";

const operators = [
  "equals",
  "not equals",
  "contains",
  "does not contain",
  "begins with",
  "does not begin with",
  "ends with",
  "does not end with",
  "today's date?",
  "is First Message?",
  "Is Conversation Live?",
];

const booleanOperators = [
  "today's date?",
  "is First Message?",
  "Is Conversation Live?",
];

const ConditionRow = ({
  condition,
  onConditionChange,
  onDelete,
  parent,
  buttonLabels = [],
  templateIds = [],
  dynamicContent,
}) => {
  const initialSet = useRef(false);

  // Initialize field with condition.field or fallback to empty string
  const [field, setField] = useState(condition.field || "");

  useEffect(() => {
    // Only set the default value for new rows
    if (parent === "ConditionAction" && !initialSet.current && !condition.field) {
      const defaultField = "message"; // Default field for new rows
      onConditionChange({
        ...condition,
        field: defaultField,
        operator: "equals",
        value: "", // Reset value for new rows
      });
      setField(defaultField);
      initialSet.current = true;
    }
  }, [parent, condition, onConditionChange]);

  // Ensure field updates correctly when props change (e.g., for existing rows)
  useEffect(() => {
    if (condition.field && condition.field !== field) {
      setField(condition.field);
    }
  }, [condition.field, field]);

  const handleFieldChange = (newField) => {
    setField(newField);

    if (newField === "ResponseLastSentTemplateMessageButtonClicked") {
      const defaultValue =
        buttonLabels?.length > 0 && templateIds?.length > 0
          ? `${buttonLabels[0]} + ${templateIds[0]}`
          : "";
      onConditionChange({
        ...condition,
        field: newField,
        value: defaultValue,
        templateId: templateIds[0] || "",
      });
    } else if (newField === "hardCoded") {
      onConditionChange({
        ...condition,
        field: newField,
        value: "", // Reset value for hardcoded
        templateId: null,
      });
    } else {
      onConditionChange({ ...condition, field: newField, value: "" });
    }
  };

  const handleOperatorChange = (e) => {
    onConditionChange({ ...condition, operator: e.target.value });
  };

  const handleValueChange = (e) => {
    const newValue = e.target ? e.target.value : e;
    onConditionChange({ ...condition, value: newValue });
  };

  const renderLeftField = () => {
    if (field === "hardCoded") {
      return (
        <ActionInputField
          label=""
          value={condition.fieldValue || ""}
          onChange={(newValue) =>
            onConditionChange({ ...condition, fieldValue: newValue })
          }
          dynamicContent={dynamicContent}
          type="input"
        />
      );
    }

    return (
      <select
        value={field}
        onChange={(e) => handleFieldChange(e.target.value)}
      >
        <option value="message">Message</option>
        {parent === "ConditionAction" && (
          <>
            <option value="ResponseLastSentTemplateMessageButtonClicked">
              Response Last Sent Message - Template Button Clicked
            </option>
            <option value="hardCoded">Hard Coded</option>
          </>
        )}
      </select>
    );
  };

  const renderValueField = () => {
    if (booleanOperators.includes(condition.operator)) {
      return (
        <select
          value={condition.value.toString()}
          onChange={handleValueChange}
        >
          <option value="true">True</option>
          <option value="false">False</option>
        </select>
      );
    }

    if (field === "ResponseLastSentTemplateMessageButtonClicked") {
      return (
        <select
          value={condition.value || ""}
          onChange={(e) => handleValueChange(e.target.value)}
        >
          {buttonLabels.map((label, index) => (
            <option key={index} value={`${label} + ${templateIds[index]}`}>
              {`${label} + ${templateIds[index]}`}
            </option>
          ))}
        </select>
      );
    }

    return (
      <div className="action-input-wrapper">
        <ActionInputField
          label=""
          value={condition.value}
          onChange={handleValueChange}
          dynamicContent={dynamicContent}
          type="input"
        />
      </div>
    );
  };

  return (
    <div className="condition-row">
      {renderLeftField()}

      <select value={condition.operator} onChange={handleOperatorChange}>
        {operators.map((op) => (
          <option key={op} value={op}>
            {op}
          </option>
        ))}
      </select>

      {renderValueField()}

      <FaTrash onClick={onDelete} className="fa-trash" />
    </div>
  );
};

export default ConditionRow;
