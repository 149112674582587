import React, { useState } from "react";
import "./QuestionsAnswers.css";
import { TbMessageQuestion } from "react-icons/tb";

const QuestionsAnswers = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleItem = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqItems = [
    {
      question: "מה היתרונות של שימוש בגמבוט לניהול תקשורת בוואטסאפ בארגון?",
      answer: (
        <>
          <p>גמבוט מאפשרת ניהול חכם ואוטומטי של תקשורת, חוסכת זמן ומשאבים, ומשפרת את היעילות של הצוותים.</p>
        </>
      ),
    },
    {
      question: "איך ניתן לנהל קמפיינים מפולטרים באמצעות גמבוט?",
      answer: (
        <>
          <p>באמצעות גמבוט ניתן ליצור פילטרים מותאמים אישית לפי תיוגים ואינפורמציה על אנשי הקשר, ולשלוח קמפיינים ממוקדים בלחיצת כפתור.</p>
        </>
      ),
    },
    {
      question: "מה הם היתרונות של שילוב AI במערכת גמבוט?",
      answer: (
        <>
          <p>גמבוט תומכת במודלים מבוססי AI שמאפשרים ניתוח שיחות, מענה אוטומטי לשאלות נפוצות, ושיפור השירות ללקוחות.</p>
        </>
      ),
    },
    {
      question: "האם ניתן לייבא רשימות אנשי קשר ולסנכרן עם המערכת?",
      answer: (
        <>
          <p>כן, גמבוט תומכת בייבוא רשימות אנשי קשר ממגוון פורמטים, כולל סנכרון עם מערכות קיימות כמו CRM.</p>
        </>
      ),
    },
    {
      question: "איך מתבצע ניהול תהליכי עבודה ושיחה אוטומטיים בגמבוט?",
      answer: (
        <>
          <p>ניתן ליצור תהליכים מוגדרים מראש המתאימים לתרחישים שונים, כולל פתיחת פניות, שליחת הודעות מותאמות, ואיסוף נתונים.</p>
        </>
      ),
    },
    {
      question: "איזו תמיכה טכנית זמינה למשתמשי הפלטפורמה?",
      answer: (
        <>
          <p>גמבוט מציעה תמיכה מלאה בצ'אט ובאימייל, כולל מדריכים, שאלות נפוצות, וסיוע טכני בזמן אמת לצרכים עסקיים.</p>
        </>
      ),
    },
  ];

  return (
    <div className="cw-fix">
      <section className="cw-section">
        <div className="faq-top">
          <h2 className="cw-section__title">שאלות & תשובות</h2>
          <p>כל התשובות לשאלות ששאלתם אותנו במהלך הזמן במקום אחד <TbMessageQuestion /></p>
          {/* <p>יש לכם שאלה חדשה? צרו איתנו קשר</p> */}
        </div>
        <div className="cw-accordion">
          {faqItems.map((item, index) => (
            <article
              className={`cw-accordion__item ${openIndex === index ? "cw-open" : ""}`}
              key={index}
            >
              <span className="cw-label" onClick={() => toggleItem(index)}>
                {item.question}
              </span>
              <div className="cw-acordion-cont">{item.answer}</div>
            </article>
          ))}
        </div>
        <div className="faq-contact">
          <h3>עדיין יש לכם שאלות?</h3>
          <a href="#" className="contact-btn">
            צרו קשר
          </a>
        </div>
      </section>
    </div>
  );
};

export default QuestionsAnswers;
