// import React, { useState } from "react";
// import "./ContactUs.css";

// const ContactUs = () => {
 

//   return (
//     <div className="ContactUs">
//    <p>צור קשר</p>
//     </div>
//   );
// };

// export default ContactUs;

// import React, { useState } from "react";
// import "./ContactUs.css";


import React, { useState } from "react";
import "./ContactUs.css";
import info from '../../../assets/logoCon.png'; 
import { FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { IoLocation } from "react-icons/io5";
import { FaCheckDouble } from "react-icons/fa6";
import { CiInstagram } from "react-icons/ci";
import { CiFacebook } from "react-icons/ci";
import { CiLinkedin } from "react-icons/ci";
import { CiYoutube } from "react-icons/ci";




const ContactUs = () => {
    const [formData, setFormData] = useState({
      name: "",
      email: "",
      phone: "",
      message: "",
    });
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      alert("הטופס נשלח בהצלחה!");
      // Add your form submission logic here
    };
  
    return (
      <div className="ContactUsContainer">
        <p className="ContactUsTitel">צור קשר</p>
        <div className="contact-container">
          <form className="contact-form" onSubmit={handleSubmit}>
            <h2>צור קשר</h2>
            <div className="form-group">
              <label htmlFor="name">שם מלא</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="הקלד את שמך"
                required
              />
            </div>
  
            <div className="form-group">
              <label htmlFor="email">אימייל</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="הקלד את האימייל שלך"
                required
              />
            </div>
  
            <div className="form-group">
              <label htmlFor="phone">טלפון</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="הקלד את מספר הטלפון שלך"
              />
            </div>
  
            <div className="form-group messageConUs">
              <label htmlFor="message">הודעה</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="כתוב הודעה"
                required
              />
            </div>
  
            <button type="submit" className="submit-btn">
              שלח
            </button>
          </form>


          <div className="additional-info">
          <img src={info} alt="info" className="infoCon"/>
          <h2 className="H2Con">מידע נוסף <FaCheckDouble /></h2>
          <p className="PCon">כדי ליצור איתנו קשר מהיר, פשוט לחצו על סמל הווצאפ שנמצא בכל עמוד  בפינה הימנית,  או לחלופין באחד מאמצעי התקשורת הבאים:</p>
            <ul>
              <li> info@GamBot.co.il <MdEmail /></li>
              <li> 055-9776-699 <FaPhone /></li>
              <li> ישראל, תל מונד <IoLocation /></li>
            </ul>
            <h2 className="H2Icon"><CiInstagram /><CiFacebook /><CiLinkedin /><CiYoutube /></h2>
          </div>
        </div>

        
      </div>
    );
  };
  
  export default ContactUs;
  