import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Table, TableHead, TableBody, TableRow, TableCell, TextField } from '@mui/material';
import './CampaignResult.css';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel'; // To show the 'x' for failed messages

const CampaignResult = ({ open, onClose, campaignResult, currenUser }) => {
    console.log(campaignResult);
    const [openRecipientList, setOpenRecipientList] = useState(false);
    const [selectedRecipients, setSelectedRecipients] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    const handleClose = () => {
        onClose();
    };

    const handleOpenRecipientList = (recipients) => {
        setSelectedRecipients(recipients);
        setOpenRecipientList(true);
    };

    const handleCloseRecipientList = () => {
        setOpenRecipientList(false);
        setSearchQuery(''); // Clear search query when closing
    };

    const formatToUserTimezone = (utcDate, timezone) => {
        if (!utcDate) return 'No date available';
      
        try {
            return new Intl.DateTimeFormat('en-GB', {
                timeZone: timezone,
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false
            }).format(new Date(utcDate));
        } catch (error) {
            console.error('Error formatting date:', error, 'Timezone:', timezone);
            return 'Invalid date';
        }
    };
    const renderMessageStatus = (status) => {
        const iconStyle = { fontSize: '16px' }; // Adjust font size here
    
        switch (status) {
            case 'read':
                return (
                    <span className="campaignResult-StatusIcon">
                        <CheckIcon style={{ color: 'blue', ...iconStyle }} />
                        <CheckIcon style={{ color: 'blue', ...iconStyle }} />
                    </span>
                ); // 2 blue checks
            case 'delivered':
                return (
                    <span className="campaignResult-StatusIcon">
                        <CheckIcon style={{ color: 'gray', ...iconStyle }} />
                        <CheckIcon style={{ color: 'gray', ...iconStyle }} />
                    </span>
                ); // 2 grey checks
            case 'sent':
                return (
                    <span className="campaignResult-StatusIcon">
                        <CheckIcon style={{ color: 'gray', ...iconStyle }} />
                    </span>
                ); // 1 gray check
            case 'failed':
                return (
                    <span className="campaignResult-StatusIcon">
                        <CancelIcon style={{ color: 'red', ...iconStyle }} />
                    </span>
                ); // red X
            default:
                return null;
        }
    };
    
    // Filter recipients based on search query
    const filteredRecipients = selectedRecipients.filter(recipient =>
        recipient?.PhoneNumber.includes(searchQuery)
    );
    console.log(campaignResult);
    return (
        <>
            <Dialog
                className='campaignResult-container-dialog'
                open={open}
                onClose={handleClose}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle className='campaignResult-container-dialog-title'>Campaign Result</DialogTitle>
                <DialogContent className='campaignResult-container-dialog-content'>
                    {campaignResult && campaignResult.length > 0 ? (
                        <Table className='campaignResult-container-dialog-tbl'>
                        <TableHead className='campaignResult-container-dialog-tbl-tablehead'>
                            <TableRow>
                                <TableCell>Runs At</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Runs By</TableCell>
                                <TableCell>Recipient Number</TableCell>
                                <TableCell>
                                    Sent
                                    <br />
                                    {renderMessageStatus('sent')}
                                </TableCell>
                                <TableCell>
                                    Delivered
                                    <br />
                                    {renderMessageStatus('delivered')}
                                </TableCell>
                                <TableCell>
                                    Read
                                    <br />
                                    {renderMessageStatus('read')}
                                </TableCell>
                                <TableCell>
                                    Failed
                                    <br />
                                    {renderMessageStatus('failed')}
                                </TableCell>
                                <TableCell>Press Button</TableCell>
                                <TableCell>Button Pressed</TableCell>
                                <TableCell>Reply</TableCell>
                                <TableCell>Spam</TableCell>
                                <TableCell>View Recipients</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
    {campaignResult.map((result, index) => {
        const total = result.CampaignResultSummary?.TotalContactsNumber || 1; // Avoid division by 0

        const calculatePercentage = (number) => ((number / total) * 100).toFixed(2);

        return (
            <TableRow key={index}>
                <TableCell>{formatToUserTimezone(result.RunsAt, currenUser?.timeZone)}</TableCell>
                <TableCell>{result.Status}</TableCell>
                <TableCell>{result.RunsByName !== 'Unknown User' ? result?.RunsByName : "Gambot"}</TableCell>
                <TableCell>{total}</TableCell>
                <TableCell>
                    <div className="percentage-cell">
                        {result.CampaignResultSummary?.NumberOfSentMessage}<br></br>
                        <span className="percentage">({calculatePercentage(result.CampaignResultSummary?.NumberOfSentMessage || 0)}%)</span>
                    </div>
                </TableCell>
                <TableCell>
                    <div className="percentage-cell">
                        {result.CampaignResultSummary?.NumberOfDeliveredMessage}<br></br>
                        <span className="percentage">({calculatePercentage(result.CampaignResultSummary?.NumberOfDeliveredMessage || 0)}%)</span>
                    </div>
                </TableCell>
                <TableCell>
                    <div className="percentage-cell">
                        {result.CampaignResultSummary?.NumberOfReadMessage}<br></br>
                        <span className="percentage">({calculatePercentage(result.CampaignResultSummary?.NumberOfReadMessage || 0)}%)</span>
                    </div>
                </TableCell>
                <TableCell>
                    <div className="percentage-cell">
                        {result.CampaignResultSummary?.NumberOfFailureOutboundMessage}<br></br>
                        <span className="percentage">({calculatePercentage(result.CampaignResultSummary?.NumberOfFailureOutboundMessage || 0)}%)</span>
                    </div>
                </TableCell>
                <TableCell>{result.CampaignResultSummary?.NumberOfClickButton}</TableCell>
                <TableCell>{result.CampaignResultSummary?.NumberOfButtonPressed}</TableCell>
                <TableCell>{result.CampaignResultSummary?.NumberOfReplyMessage}</TableCell>
                <TableCell>{result.CampaignResultSummary?.NumberOfIsSpam}</TableCell>
                <TableCell>
                    <Button 
                        variant="outlined" 
                        onClick={() => handleOpenRecipientList(result.CampaignRecipientData)}
                    >
                        View Recipients
                    </Button>
                </TableCell>
            </TableRow>
        );
    })}
</TableBody>

                    </Table>
                    ) : (
                        <p>No campaign results available.</p>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Recipient List Dialog */}
            <Dialog
                open={openRecipientList}
                onClose={handleCloseRecipientList}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>Recipient List</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Search by Phone Number"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Phone Number</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Details</TableCell>
                                <TableCell>Message ID</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {filteredRecipients?.length > 0 ? (
                            filteredRecipients?.map((recipient, index) => (
                                <TableRow key={index}>
                                    <TableCell>{recipient?.PhoneNumber}</TableCell>
                                    <TableCell>
                                        <span className="recipientStatus">
                                            <span className="recipientStatusLabel">{recipient?.Status}</span>
                                            {renderMessageStatus(recipient?.Status ?? recipient?.status)}
                                        </span>
                                    </TableCell>
                                    <TableCell>{recipient?.errorMessage}</TableCell>
                                    <TableCell>{recipient?.MessageId}</TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={3} style={{ textAlign: 'center' }}>
                                    No recipients found.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>

                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseRecipientList} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CampaignResult;
