import React, { useState } from 'react';
import './WhyWhatsapp.css';
import { PiStarOfDavidThin } from "react-icons/pi";
import { LuMessageSquare } from "react-icons/lu";
import { RiCustomerService2Fill } from "react-icons/ri";
import { FaSatelliteDish } from "react-icons/fa";
import { FaRobot } from "react-icons/fa6";

const WhyWhatsapp = () => {
  const [hoveredFeature, setHoveredFeature] = useState(null);

  const features = [
    {
      id: 1,
      icon: <PiStarOfDavidThin />,
      title: "הכי ישראלי שיש",
      description: "עם 95% שימוש בישראל, וואטסאפ היא אפליקציית המסרים הפופולרית ביותר בארץ. כמעט כל לקוח שלכם משתמש בה ביום-יום, מה שמבטיח שתוכלו להגיע לכולם במקום אחד, במהירות ובקלות.",
    },
    {
      id: 2,
      icon: <LuMessageSquare />,
      title: "98% פתיחת הודעות",
      description: "לעומת 20%-30% בלבד באימיילים ו-45%-50% ב-SMS, הודעות בוואטסאפ נפתחות כמעט תמיד – הבחירה הטבעית למעורבות מקסימלית של לקוחות.",
    },
    {
      id: 3,
      icon: <FaRobot />,
      title: "אוטומיצה מתקדמת ו- AI",
      // title: "AI-יכולות אוטומציה",
      description: "עם גמבוט, הבוט הארגוני החכם שפיתחנו, תוכלו להפוך את התקשורת הארגונית שלכם ליעילה מתמיד. המערכת מאפשרת בניית תהליכי שיחה אוטומטיים, שימוש בתבניות מותאמות אישית, וחיבור למערכות CRM ליצירת אוטומציה מלאה שעובדת עבורכם. בנוסף, מודל ה-AI המתקדם שלנו מאפשר לכם להגדיר סוכן מותאם אישית לארגון, שמספק תשובות, מנהל אינטראקציות עם לקוחות, ומטפל בפניות באופן חכם ואפקטיבי.",
    },
    {
      id: 4,
      icon: <RiCustomerService2Fill />,
      title: "שירות אישי – בלי מאמץ",
      description: "וואטסאפ מאפשרת לכם להציע חוויית שירות ישירה ואישית, שמרגישה ללקוח כמו שיחה טבעית. זהו ערוץ תקשורת שמשדר מקצועיות, זמינות ונוחות – בלי התחושה של הודעה שיווקית מנוכרת.",
    },
    {
      id: 5,
      icon: <FaSatelliteDish />,
      title: "תכונות תקשורת עשירות",
      description: "מעבר לטקסט, וואטסאפ תומך במגוון מדיות כמו תמונות, וידאו, מסמכים ועוד. הפלטפורמה מאפשרת לכם להעביר מסרים ויזואליים, לשלב תפריטים אינטראקטיביים, וליצור חוויית משתמש דינמית שמעצימה את התקשורת עם הלקוחות.",
    },
  ];

  return (
    <div className="why-whatsapp">
      <h2 className="main-title"> ?  למה וואטסאפ הוא כלי התקשורת הארגוני המושלם עבורכם </h2>
      <div className="features-container">
        {features.map((feature) => (
          <div
            className="feature-card"
            key={feature.id}
            onMouseEnter={() => setHoveredFeature(feature.id)}
            onMouseLeave={() => setHoveredFeature(null)}
          >
            <div className="icon-container">{feature.icon}</div>
            <h3 className="feature-title">{feature.title}</h3>
            {hoveredFeature !== feature.id && (
              <button className="read-more-btn">קרא עוד</button>
            )}
            {hoveredFeature === feature.id && (
              <div className="hover-overlay">
                <p className="feature-description">{feature.description}</p>
              </div>
            )}
          </div>
        ))}
      </div>
      <p className="footerText">!הלקוחות שלכם כבר שם – הגיע הזמן שגם הארגון שלכם יתקדם לוואטסאפ</p>
    </div>
  );
};

export default WhyWhatsapp;
