// import React, { useState } from "react";
// import "./HowWorks.css";
// import { FaUsersCog } from "react-icons/fa";
// import { GrSystem } from "react-icons/gr";

// const HowWorks = () => {
//   const [currentStep, setCurrentStep] = useState(0); // Start from 0 so the first step shows only after clicking "Next"

//   const steps = [
//     {
//       title: "שלב 1: האזור האישי שלכם",
//       text: "איזה כיף! השלב הכי מרגש בתהליך שלנו.  בשלב זה תיצרו לחברה, לעסק או לארגון שלכם משתמש אצלנו. מאחורי הקלעים המערכת שלנו תקים לכם אזור אישי מותאם במיוחד עבורכם."
//     },
//     { title: "שלב 2: למידת המערכת",
//        text: "פנו לכם חצי שעה, תכינו לכם כוס קפה ועוגה ולימדו את המערכת, עברו על דברים חשובים ותתחילו להכיר את גמבוט. כמובן בשלב זה הצוות שלנו עומד לרשותכם ויענה על כל שאלה בווצאפ וגם בשיחת טלפון עם נציג אנושי."
//     },

//     { title: "שלב 3: טמפלייטים", text: "צרו טמפלייטים מותאמים אישית כדי לחסוך זמן ולייעל את העבודה." },
//     { title: "שלב 4: תהליכי עבודה אוטומטיים", text: "תכננו תהליכים אוטומטיים לניהול חכם של התקשורת." },
//     { title: "שלב 5: ניהול אנשי קשר", text: "סדרו ותייגו את אנשי הקשר שלכם לפי קטגוריות." },
//     { title: "שלב 6: ניתוח ובקרה", text: "עקבו אחר ביצועי התקשורת שלכם בעזרת דוחות ונתונים." },
//     { title: "שלב 7: אינטגרציות מתקדמות", text: "חברו את המערכת עם כלים נוספים כמו CRM וניהול משימות." }
//   ];

//   const updateProgressWidth = () => {
//     return currentStep > 0
//       ? ((currentStep - 1) / (steps.length - 1)) * 100 + "%"
//       : "0%";
//   };

//   const handleNext = () => {
//     setCurrentStep((prevStep) => (prevStep < steps.length ? prevStep + 1 : prevStep));
//   };

//   const handlePrev = () => {
//     setCurrentStep((prevStep) => (prevStep > 0 ? prevStep - 1 : prevStep));
//   };

//   return (
//     <div id="HowWorks">
//       <div className="containerHowWorks">
//       <h1 className="titleHowWorks">
//   איך זה <span className="highlight">עובד?</span>
// </h1>

//         {/* <p className="intro-text">הפלטפורמה המתקדמת לניהול תקשורת בוואטסאפ לארגונים.</p> */}
//         <div className="progress-wrap">
//           <div
//             className="progress"
//             id="progress"
//             style={{ width: updateProgressWidth() }}
//           ></div>
//           {steps.map((_, index) => (
//             <div
//               key={index}
//               className={`step ${index < currentStep ? "active" : ""}`}
//             >
//               {index + 1}
//             </div>
//           ))}
//         </div>

//         <div className="button-container">
//           <button
//             className="btn"
//             id="next"
//             onClick={handleNext}
//             disabled={currentStep === steps.length}
//           >
//             הבא
//           </button>
//           <button
//             className="btn"
//             id="prev"
//             onClick={handlePrev}
//             disabled={currentStep === 0}
//           >
//             הקודם
//           </button>
//         </div>

      
//         {currentStep > 0 && (
//   <div className="containerHowWorks-step-content">
//     {currentStep === 1 && (
//       <FaUsersCog className="step-icon" />
//     )}
//     {currentStep === 2 && (
//       <GrSystem className="step-icon" />
//     )}

//     <h2>{steps[currentStep - 1].title}</h2>
//     <p>{steps[currentStep - 1].text}</p>

//     {currentStep === 1 && (
//       <button 
//         className="create-account-btn" 
//         onClick={() => window.location.href = '/create-account'}
//       >
//         ליצירת חשבון
//       </button>
//     )}
//   </div>
// )}
//       </div>
//     </div>
//   );
// };

// export default HowWorks;




import React from "react";
import "./HowWorks.css";

const HowWorks = () => {
  return (
    <div id="HowWorks">
      <div className="containerHowWorks">
        <h1 className="titleHowWorks">
          איך זה עובד ?
        </h1>

        <ul className="timeline-cards">
          <li style={{ "--accent-color": "#41516C" }}>
            <div className="date">1</div>
            <div className="title2HowWorks">האזור האישי שלכם</div>
            <div className="descr">
            איזה כיף! השלב הכי מרגש בתהליך שלנו.  בשלב זה תיצרו לחברה, לעסק או לארגון שלכם משתמש אצלנו. מאחורי הקלעים המערכת שלנו תקים לכם אזור אישי מותאם במיוחד עבורכם.
            </div>
          </li>
          <li style={{ "--accent-color": "#FBCA3E" }}>
            <div className="date">2</div>
            <div className="title2HowWorks">למידת המערכת</div>
            <div className="descr">
            פנו לכם חצי שעה, תכינו לכם כוס קפה ועוגה ולימדו את המערכת, עברו על דברים חשובים ותתחילו להכיר את גמבוט. כמובן בשלב זה הצוות שלנו עומד לרשותכם ויענה על כל שאלה בווצאפ וגם בשיחת טלפון עם נציג אנושי.
            </div>
          </li>
          <li style={{ "--accent-color": "#E24A68" }}>
            <div className="date">3</div>
            <div className="title2HowWorks">סנכרון לקוחות</div>
            <div className="descr">
             בעזרת קובץ אקסל של גמבוט עם טמפלייט מוכן מראש, תעלו למערכת את אנשי הקשר שלכם ותראו אותם תוך רגע מסודרים באזור האישי
            </div>
          </li>
          <li style={{ "--accent-color": "#1B5F8C" }}>
            <div className="date">4</div>
            <div className="title2HowWorks">יצירת הגמבוט שלכם</div>
            <div className="descr">
              בשלב זה, אתם תגדירו תבניות מותאמות אישית עם משתנים שונים לצרכי העסק שלכם. בנוסף, תיצרו אוטומציות שיענו ללקוחות שלכם באופן מיידי ומדוייק, או תרחיבו אותן עם AI שידבר ישירות עם הלקוחות בשמכם.
            </div>
          </li>
          <li style={{ "--accent-color": "#4CADAD" }}>
            <div className="date">5</div>
            <div className="title2HowWorks">אתם הולכים לים וגמבוט עובד בשבילכם</div>
            <div className="descr">
            בשלב זה, גמבוט יעבוד עבורכם מאחורי הקלעים וינהל לכם את ממשק התקשורת בווצאפ עם הלקוחות שלכם.
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default HowWorks;
