import React, { useState } from "react";
import "./Examples.css";

const Examples = () => {
 

  return (
    <div className="Examples">
      <section className="Examples-section">
         דוגמאות
      </section>
    </div>
  );
};

export default Examples;
