import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import Switch from 'react-switch';
import './SendHttpRequestAction.css';
import ActionInputField from "../WorkflowDesigner/ActionInputField";

const SendHttpRequestAction = ({ steps, onChange, initialConfig = {}, dynamicContent = [] }) => {
  const [endpoint, setEndpoint] = useState(initialConfig.endpoint || '');
  const [method, setMethod] = useState(initialConfig.method || 'POST');
  const [headers, setHeaders] = useState(
    initialConfig.headers
      ? Object.entries(initialConfig.headers).map(([key, value]) => ({ key, value }))
      : [{ key: '', value: '' }]
  );
  const [bodyOption, setBodyOption] = useState(initialConfig.bodyOption || '');
  const [bodyBuildOption, setBodyBuildOption] = useState(initialConfig.bodyBuildOption || 'steps');
  const [body, setBody] = useState(
    typeof initialConfig.body === 'object' && initialConfig.body !== null
      ? JSON.stringify(initialConfig.body, null, 2)
      : initialConfig.body || ''
  );
  const [selectedQuestions, setSelectedQuestions] = useState(initialConfig.selectedQuestions || []);
  const [handleResponse, setHandleResponse] = useState(initialConfig.handleResponse || false);
  const [responseJsonSchema, setResponseJsonSchema] = useState(
    typeof initialConfig.responseJsonSchema === 'object' && initialConfig.responseJsonSchema !== null
      ? JSON.stringify(initialConfig.responseJsonSchema, null, 2)
      : initialConfig.responseJsonSchema || ''
  );
  const [isResponseJsonValid, setIsResponseJsonValid] = useState(true);
  const [responseFields, setResponseFields] = useState([]);
  const [isBodyJsonValid, setIsBodyJsonValid] = useState(true);
  const initialLoad = useRef(true);

  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
      return;
    }

    const config = {
      endpoint,
      method,
      headers: headers.reduce((acc, { key, value }) => {
        if (key) acc[key] = value;
        return acc;
      }, {}),
      body:
        bodyOption === 'allAnswers'
          ? getAllAnswers()
          : bodyBuildOption === 'json'
          ? parseBody(body)
          : selectedQuestions,
      bodyOption,
      bodyBuildOption,
      selectedQuestions: bodyBuildOption === 'steps' ? selectedQuestions : [],
      handleResponse,
      responseJsonSchema: handleResponse ? parseBody(responseJsonSchema) : null,
    };

    onChange(config);
  }, [
    endpoint,
    method,
    headers,
    bodyOption,
    bodyBuildOption,
    body,
    selectedQuestions,
    handleResponse,
    responseJsonSchema,
    onChange,
  ]);

  const parseBody = (bodyString) => {
    try {
      return JSON.parse(bodyString);
    } catch {
      return {};
    }
  };

  const getAllAnswers = () => {
    const answers = {};
    steps?.forEach((step) => {
      if (step?.type === 'trigger' && step?.action === 'IncomingMessage') {
        answers[step?.config?.question] = step?.config?.answer;
      }
    });
    return answers;
  };

  const handleJsonSchemaChange = (e) => {
    const value = e.target.value;
    setResponseJsonSchema(value);
    if (value) {
      try {
        const schema = JSON.parse(value);
        setResponseFields(parseResponseSchema(schema));
        setIsResponseJsonValid(true);
      } catch {
        setIsResponseJsonValid(false);
        setResponseFields([]);
      }
    }
  };

  const parseResponseSchema = (schema, prefix = 'Response') => {
    const fields = [];
    const traverseSchema = (obj, currentPrefix) => {
      Object.entries(obj).forEach(([key, value]) => {
        const fieldKey = `${currentPrefix}_${key}`;
        fields.push({ value: fieldKey, label: `${currentPrefix} - ${key}` });
        if (typeof value === 'object' && value !== null) {
          traverseSchema(value, fieldKey);
        }
      });
    };
    traverseSchema(schema, prefix);
    return fields;
  };

  const handleJsonBodyChange = (value) => {
    setBody(value);
    if (value) {
      try {
        JSON.parse(value);
        setIsBodyJsonValid(true);
      } catch {
        setIsBodyJsonValid(false);
      }
    }
  };

  const handleHeaderChange = (index, field, value) => {
    const newHeaders = [...headers];
    newHeaders[index][field] = value;
    setHeaders(newHeaders);
  };

  const handleAddHeader = () => {
    setHeaders([...headers, { key: '', value: '' }]);
  };

  const handleRemoveHeader = (index) => {
    setHeaders(headers.filter((_, i) => i !== index));
  };

  return (
    <div className="send-http-request-action">
      <label>
        Endpoint:
        <ActionInputField
          label=""
          value={endpoint}
          onChange={(value) => setEndpoint(value)}
          dynamicContent={dynamicContent}
          type="input"
        />
      </label>
      <label>
        Method:
        <select value={method} onChange={(e) => setMethod(e.target.value)}>
          <option value="GET">GET</option>
          <option value="POST">POST</option>
          <option value="PUT">PUT</option>
          <option value="DELETE">DELETE</option>
        </select>
      </label>
      <label>
        Headers:
        {headers.map((header, index) => (
          <div key={index} className="send-http-request-action-header-row">
            <input
              type="text"
              placeholder="Key"
              value={header.key}
              onChange={(e) => handleHeaderChange(index, 'key', e.target.value)}
            />
            <input
              type="text"
              placeholder="Value"
              value={header.value}
              onChange={(e) => handleHeaderChange(index, 'value', e.target.value)}
            />
            <button
              className="send-http-request-action-remove"
              type="button"
              onClick={() => handleRemoveHeader(index)}
            >
              Remove
            </button>
          </div>
        ))}
        <button type="button" onClick={handleAddHeader}>
          Add Header
        </button>
      </label>
      {method !== 'GET' && (
        <>
          <label>
            Body Option:
            <select value={bodyOption} onChange={(e) => setBodyOption(e.target.value)}>
              <option value="">Empty</option>
              <option value="allAnswers">All Answers</option>
              <option value="manual">Build Manually</option>
            </select>
          </label>
          {bodyOption === 'manual' && (
            <>
              <label>
                Build Body:
                <textarea
                  value={body}
                  onChange={(e) => handleJsonBodyChange(e.target.value)}
                  placeholder="Enter JSON Body"
                  className={`json-body-textarea ${isBodyJsonValid ? '' : 'invalid'}`}
                />
              </label>
              {!isBodyJsonValid && <small>Invalid JSON format.</small>}
            </>
          )}
        </>
      )}
      <label>
        Handle Response:
        <Switch
          checked={handleResponse}
          onChange={setHandleResponse}
          height={20}
          width={48}
        />
      </label>
      {handleResponse && (
        <>
          <label>
            Response JSON Schema:
            <textarea
              value={responseJsonSchema}
              onChange={handleJsonSchemaChange}
              placeholder="Enter JSON schema for response"
              className={`json-schema-textarea ${isResponseJsonValid ? '' : 'invalid'}`}
            />
          </label>
          {!isResponseJsonValid && <small>Invalid JSON schema.</small>}
          {/* {responseFields.length > 0 && (
            <div>
              <label>Response Fields:</label>
              <Select isMulti options={responseFields} />
            </div>
          )} */}
        </>
      )}
    </div>
  );
};

export default SendHttpRequestAction;
