import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import HomePageMainSection from '../HomePageMainSection/HomePageMainSection';
import WhyWhatsapp from '../WhyWhatsapp/WhyWhatsapp';
import OurFeatures from '../OurFeatures/OurFeatures';
import HowWorks from '../HowWorks/HowWorks';
import QuestionsAnswers from '../QuestionsAnswers/QuestionsAnswers';
import Examples from '../Examples/Examples';

import './HomePageElements.css';


const HomePageElements = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.state?.scrollTo) {
      const element = document.getElementById(location.state.scrollTo);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.state]);

  return (
    <main className="HomePageElements">
      <section id="HomePageMainSection">
        <HomePageMainSection />
      </section>
      <section id="why-whatsapp">
        <WhyWhatsapp />
      </section>
      <section id="our-features">
        <OurFeatures />
      </section>
      <section id="Examples">
        <Examples />
      </section>
      <section id="HowWorks">
        <HowWorks />
      </section>
      <section id="QuestionsAnswers">
        <QuestionsAnswers />
      </section>
    </main>
  );
};

export default HomePageElements;
