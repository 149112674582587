// import React, { useEffect } from 'react';
// import './HomePageMainSection.css';
// import GambotHomePage from '../../../assets/homePage.png'; 


// const HomePageMainSection = () => {
 
//     return (
//         <>
//             <div id="HomePageMainSection">
//             <main className="mainHhomePage">
//                 <section className="section banner banner-section">
//                     <div className="container banner-column">
//                         <img className="banner-image" src={GambotHomePage} alt="GambotHomePageImage" />
//                         <div className="banner-inner">
//                             <br></br><br></br><br></br>
//                             <h1 className="heading-xl">  תנו לגמבוט לעבוד בשבילכם!</h1>
//                             <p>הפלטפורמה המתקדמת לניהול תקשורת בוואטסאפ לארגונים.</p>
                            
//                             <p className="paragraph">
//                             גמבוט מאפשרת לארגונים לנהל את התקשורת שלהם בוואטסאפ בצורה חכמה, אוטומטית ויעילה. בעזרת כלים מתקדמים כגון ניהול אנשי קשר עם תיוגים, שליחת קמפיינים מפולטרים, יצירת תהליכי עבודה ושיחה אוטומטיים, ותמיכה במודלים מבוססי AI - תוכלו להפוך עד 80% מהתקשורת שלכם לאוטומטית, ולמקד את הצוותים שלכם ב-20% החשובים ביותר .   
//                                                     </p>
//                             <div className="btn-container">
//                             <button className="btn btn-darken btn-inline">
//                            סרטון הסבר 🎥 
//                             </button>
//                             <button className="btn btn-secondary btn-inline">
//                                 תיאום פגישת דמו 
//                                 <i className="bx bx-left-arrow-alt"></i>
//                             </button>
//                         </div>

//                         </div>
//                         <div className="banner-links">
//                             <a href="#" title=""><i className="bx bxl-facebook"></i></a>
//                             <a href="#" title=""><i className="bx bxl-instagram"></i></a>
//                             <a href="#" title=""><i className="bx bxl-whatsapp"></i></a>
//                             <a href="#" title=""><i className="bx bxl-youtube"></i></a>
//                         </div>
//                     </div>
//                     <br></br><br></br><br></br>

//                 </section>
//             </main>
//             </div>
//         </>
//     );
// };

// export default HomePageMainSection;






import React from 'react';
import './HomePageMainSection.css';
import GambotHomePage from '../../../assets/homePage.png';

const HomePageMainSection = () => {
  
  const scrollToNextSection = () => {
    const nextSection = document.getElementById('why-whatsapp');
    if (nextSection) {
      nextSection.scrollIntoView({ behavior: 'smooth' });
    }
  };



  return (
    <div id="HomePageMainSection">
      <main className="mainHhomePage">
        <section className="section banner banner-section">
          <div className="container banner-column">
             <img
              className="banner-image"
              src={GambotHomePage}
              alt="GambotHomePageImage"
            />  
            <div className="banner-inner">
              <h1 className="heading-xl">תנו לגמבוט לעבוד בשבילכם!</h1>
              <p className="heading-x">הפלטפורמה המתקדמת לניהול תקשורת בוואטסאפ לארגונים.</p>
              <p className="paragraph">
                גמבוט מאפשרת לארגונים לנהל את התקשורת שלהם בוואטסאפ בצורה
                חכמה, אוטומטית ויעילה. בעזרת כלים מתקדמים כגון ניהול אנשי קשר
                עם תיוגים, שליחת קמפיינים מפולטרים, יצירת תהליכי עבודה ושיחה
                אוטומטיים, ותמיכה במודלים מבוססי AI - תוכלו להפוך עד 80% מהתקשורת
                שלכם לאוטומטית, ולמקד את הצוותים שלכם ב-20% החשובים ביותר.
              </p>
              <div className="btn-container">
                <button className="btn btn-darken btn-inline">סרטון הסבר 🎥</button>
                <button className="btn btn-secondary btn-inline">
                  תיאום פגישת דמו <i className="bx bx-left-arrow-alt"></i>
                </button>
              </div>
            </div>
          </div>
          {/* חץ לגלילה */}
          <div className="scroll-arrow" onClick={scrollToNextSection}>
            ⬇
          </div>
        </section>
           {/* בועות מטושטשות */}
           <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </main>
    </div>
  );
};

export default HomePageMainSection;







