import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Login.css';

const Login = ({ setUser, setWebSocket }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showForgotPassword, setShowForgotPassword] = useState(false); // Toggle forgot password UI
  const [resetEmail, setResetEmail] = useState('');
  const [resetMessage, setResetMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isRecoveryLoading, setIsRecoveryLoading] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [generalError, setGeneralError] = useState('');

  // וולידציה על המייל
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  
  // איפוס טפסים
  const resetFormStates = () => {
    setEmail('');
    setPassword('');
    setResetEmail('');
    setEmailError('');
    setPasswordError('');
    setGeneralError('');
    setResetMessage('');
  };
  
  // טיפול במעבר לטופס שחזור סיסמה
  const handleShowForgotPassword = () => {
    resetFormStates(); // איפוס הטפסים
    setShowForgotPassword(true); // מעבר לטופס שחזור סיסמה
  };
  
  // טיפול במעבר לטופס כניסה
  const handleShowLoginForm = () => {
    resetFormStates(); // איפוס הטפסים
    setShowForgotPassword(false); // חזרה לטופס כניסה
  };
  
  // טיפול בכניסה לאזור האישי
  const signInWithEmailAndPassword = () => {
    setEmailError('');
    setPasswordError('');
    setGeneralError('');
  
    if (!isValidEmail(email)) {
      setEmailError('אנא הזן מייל חוקי');
      return;
    }
  
    if (!password) {
      setPasswordError('אנא הזן סיסמה');
      return;
    }
  
    setIsLoading(true); // הפעלת מצב טעינה
    axios
      .post("https://gambot.azurewebsites.net/api/Webhooks/authenticate", {
        username: email,
        password,
      })
      .then((response) => {
        const user = response.data.userData;
        const newUser = {
          fullname: user.UserName,
          email: user.UserEmail,
          photoURL: response.data.userCredential.photoURL,
          userId: response.data.userCredential.Uid,
          organization: user.Organization,
          wabaNumber: user.wabaNumber,
          timeZone: user?.timeZone,
        };
        setUser(newUser); // עדכון מצב המשתמש
    
        // מעבר לעמוד אנשי הקשר
        setTimeout(() => {
          navigate('/Contacts');
        }, 100);
    
        localStorage.setItem('user', JSON.stringify(newUser));
      })
      .catch((error) => {
        console.error("Login Error:", error);
  
        // אם השגיאה מצד השרת
        if (error.response && error.response.status === 401) {
          setGeneralError('שם משתמש או סיסמה לא תקינים');
        } else {
          setGeneralError('שם משתמש או סיסמה לא תקינים');
        }
  
        // איפוס השדות לאחר שגיאה
        setEmail('');
        setPassword('');
      })
      .finally(() => {
        setIsLoading(false); 
      });
  };
  
  // טיפול באיפוס סיסמה
  const handleForgotPassword = () => {
    setResetMessage(''); // איפוס הודעה קודמת
    setEmailError(''); // איפוס שגיאת אימייל
  
    if (!isValidEmail(resetEmail)) {
      setEmailError('אנא הזן מייל חוקי');
      return;
    }
  
    setIsRecoveryLoading(true); // הפעלת מצב טעינה
  
    axios
      .post("https://gambot.azurewebsites.net/api/Webhooks/forgotPassword", {
        email: resetEmail,
      })
      .then((response) => {
        if (response.data?.Success) {
          setResetMessage("מייל לשחזור סיסמה נשלח בהצלחה");
        } else {
          setResetMessage("המייל שצירפת לא קיים במערכת. אנא הזן שנית / בדוק מול מנהל המערכת שלך");
        }
  
        // איפוס השדה לאחר הצלחה
        setResetEmail('');
      })
      .catch((error) => {
        console.error("Forgot Password Error:", error);
  
        if (error.response && error.response.status === 404) {
          setResetMessage("משתמש זה לא קיים");
        } else {
          setResetMessage("אירעה שגיאה. נסה שוב מאוחר יותר.");
        }
  
        // איפוס השדה לאחר שגיאה
        setResetEmail('');
      })
      .finally(() => {
        setIsRecoveryLoading(false);
      });
  };
  
  
  return (
    <div class="login-page">

      <div className="login-container">

      {/* <img src="./logoGam.png" alt="" className="login-logo" /> */}

  <p className="login-name">
    {showForgotPassword ? 'שחזור סיסמה' : 'כניסה למערכת'}
  </p>
  {!showForgotPassword ? (
    <>
      <div className='login-page-inputs'>
          <div>
          <input
            type="email"
            placeholder="אימייל"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className='login-page-inputs-email'
          />
          {emailError && <p className="login-container-error-message">{emailError}</p>}
        </div>
        <div>
          <input
            type="password"
            placeholder="סיסמה"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className='login-page-inputs-password'

          />
          {passwordError && <p className="login-container-error-message">{passwordError}</p>}
        </div>
        <br />
        <button className="personal-area-btn" onClick={signInWithEmailAndPassword} disabled={isLoading}>
          {isLoading ? (
            <span className="loading-spinner"></span> 
          ) : (
            'כניסה'
          )}
        </button>
        {generalError && <p className="login-container-general-error-message">{generalError}</p>}

      </div>
      <p className="forgot-password" onClick={handleShowForgotPassword}>
        ? כבר שכחנו
      </p>
    </>
  ) : (
    <div>
     <div>
        <input
          type="email"
          placeholder="אימייל"
          value={resetEmail}
          onChange={(e) => setResetEmail(e.target.value)}
          className='emailrecoverylogin'
        />
        {emailError && <p className="login-container-error-message">{emailError}</p>}
      </div>
      <button className="Email-recovery" onClick={handleForgotPassword} disabled={isRecoveryLoading}>
        {isRecoveryLoading ? (
          <span className="loading-spinner"></span>
        ) : (
          '? קדימה, שנשחזר'
        )}
      </button>
      <p>{resetMessage}</p>
      <p className="back-to-login" onClick={handleShowLoginForm}>
        חזרה לכניסה
      </p>
    </div>
  )}
</div>

    </div>
  );
};

export default Login;